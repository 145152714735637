import { Button } from '@dropbox/dig-components/buttons';
import { FormLabel, FormRow } from '@dropbox/dig-components/form_row';
import { Modal } from '@dropbox/dig-components/modal';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Stack } from '@dropbox/dig-foundations';
import { AppShortcutPreferencesItem } from '@mirage/service-settings/service/types';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import { MAX_INPUT_LENGTH } from '@mirage/shared/util/constants';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import i18n from '@mirage/translations';
import { useMemo, useState } from 'react';

interface EditQuickLinkModalProps {
  quickLink?: AppShortcutPreferencesItem;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (data: AppShortcutPreferencesItem) => void;
}

export const EditQuickLinkModal: React.FC<EditQuickLinkModalProps> = ({
  quickLink,
  isOpen,
  onCancel,
  onSubmit,
}) => {
  const [title, setTitle] = useState(quickLink?.title);
  const [url, setUrl] = useState(quickLink?.url);
  const isDarkMode = useTheme();

  const handleSubmit = () => {
    if (title && url) {
      const favicon = faviconSrcForSrcUrl(url, 32, isDarkMode);
      onSubmit({
        title,
        url,
        favicon,
      });
    }
  };

  const inputValid = useMemo(() => {
    return Boolean(title && url);
  }, [title, url]);

  return (
    <Modal
      open={isOpen}
      width="small"
      withCloseButton="Close"
      onRequestClose={onCancel}
    >
      <Modal.Header>
        <Modal.Title size="small">Edit Quick Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap="24px">
          <FormRow>
            <FormLabel>Name</FormLabel>
            <TextInput
              value={title}
              maxLength={MAX_INPUT_LENGTH}
              onChange={(e) => setTitle(e.currentTarget.value)}
              placeholder={i18n.t('quick_link_title_placeholder')}
            />
          </FormRow>
          <FormRow>
            <FormLabel>URL</FormLabel>
            <TextInput
              value={url}
              maxLength={MAX_INPUT_LENGTH}
              onChange={(e) => setUrl(e.currentTarget.value)}
              placeholder={i18n.t('quick_link_url_placeholder')}
            />
          </FormRow>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="opacity" onClick={onCancel}>
          {i18n.t('create_stack_modal_cancel_button')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!inputValid}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
