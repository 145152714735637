import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AddLine,
  GlobeFill,
  MoreHorizontalLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import styled from '@emotion/styled';
import useExtensionBasedBrowserHistory from '@mirage/service-browser-history-v1/useBrowserHistory';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { AppShortcutPreferencesItem } from '@mirage/service-settings/service/types';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import useSettings from '@mirage/service-settings/useSettings';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { urlToDomainName } from '@mirage/shared/util/urlRulesUtil';
import i18n from '@mirage/translations';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { EditQuickLinkModal } from './EditQuickLinkModal';
import styles from './QuickLinks.module.css';
import { getAppsFromConnections } from './quickLinksApps';
import { resolveBrowserHistory } from './quickLinksResolver';

interface QuickLinksProps {
  accountId: string;
}

export const QuickLinks: React.FC<QuickLinksProps> = ({ accountId }) => {
  const isDarkMode = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [useFallback, setUseFallback] = useState<boolean>(false);
  const { connections } = useConnectors();
  const [editMenuOpen, setEditMenuOpen] = useState<number>();
  const [editingQuickLinkIndex, setEditingQuickLinkIndex] =
    useState<number>(-2);
  const [browserHistoryList, setBrowserHistoryList] = useState<
    AppShortcutPreferencesItem[]
  >([]);
  const { settings } = useSettings(['appShortcuts']);
  const { browserHistory, refreshBrowserHistory } =
    useExtensionBasedBrowserHistory(true, accountId);

  const settingsSet = useMemo(() => {
    return settings?.appShortcuts.edited;
  }, [settings]);

  const onMenuToggle = (isOpen: boolean, idx: number) => {
    setEditMenuOpen(isOpen ? idx : undefined);
  };

  const addOrUpdateQuickLink = (data: AppShortcutPreferencesItem) => {
    setBrowserHistoryList((prev) => {
      const next = [...prev];
      if (editingQuickLinkIndex === -1) {
        next.push(data);
      } else {
        next[editingQuickLinkIndex] = data;
      }
      return next;
    });
    setEditingQuickLinkIndex(-2);
  };

  const deleteQuickLink = (idx: number) => {
    setBrowserHistoryList((prev) => {
      const next = [...prev];
      next.splice(idx, 1);
      return next;
    });
  };

  useEffect(() => {
    if (settingsSet) {
      setLoading(false);
      setBrowserHistoryList(settings?.appShortcuts.shortcuts || []);
    } else {
      setLoading(true);
      refreshBrowserHistory();
    }
  }, [accountId, settingsSet]);

  useEffect(() => {
    if (settingsSet) {
      return;
    }
    const browserHistoryArray = Object.values(browserHistory.contentInfos);
    if (browserHistoryArray.length > 0) {
      setLoading(false);
      setBrowserHistoryList(
        resolveBrowserHistory(browserHistoryArray, 6, isDarkMode),
      );
    }
  }, [browserHistory, isDarkMode, settingsSet]);

  useEffect(() => {
    if (settingsSet) {
      return;
    }
    const timeout = setTimeout(() => {
      if (browserHistoryList.length === 0) {
        setUseFallback(true);
        setLoading(false);
      }
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [browserHistoryList.length]);

  useEffect(() => {
    if (useFallback && !settingsSet) {
      setBrowserHistoryList(getAppsFromConnections(connections, 6, isDarkMode));
    }
  }, [useFallback, isDarkMode]);

  const placeholderArray = Array.from({
    length: Math.max(0, 5 - browserHistoryList.length),
  });

  return (
    <div className={styles.quickLinksContainer}>
      <div className={styles.quickLinksTitle}>
        <Text isBold>{i18n.t('quick_links_title')}</Text>
      </div>
      {loading ? (
        <ResultContainer
          isDarkMode={isDarkMode}
          className={styles.flexContainer}
        >
          {Array.from({ length: 6 }).map((_, idx) => (
            <IconShimmer key={idx} size="40px" />
          ))}
        </ResultContainer>
      ) : (
        <div className={styles.flexContainer}>
          {browserHistoryList.map((item, idx) => {
            return (
              <div className={styles.menuContainer} key={item.url}>
                <DigTooltip
                  title={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Text isBold size="small">
                        {item.title}
                      </Text>
                      <Text color="subtle" size="small">
                        {urlToDomainName(item.url)}
                      </Text>
                    </Box>
                  }
                  key={item.url}
                  openDelay={150}
                >
                  <Button
                    variant="outline"
                    size="large"
                    target="_blank"
                    href={item.url}
                    className={styles.button}
                  >
                    {item.favicon ? (
                      <img
                        className={styles.faviconImg}
                        alt={item.title}
                        src={item.favicon}
                      />
                    ) : (
                      <GlobeFill className={styles.faviconImg} />
                    )}
                  </Button>
                </DigTooltip>
                <div className={styles.menuButtonContainer}>
                  <Menu.Wrapper
                    hasMaxHeight={480}
                    onToggle={({ isOpen }) => onMenuToggle(isOpen, idx)}
                  >
                    {({ getTriggerProps, getContentProps }) => (
                      <>
                        <IconButton
                          {...getTriggerProps()}
                          size="small"
                          variant="transparent"
                          className={styles.iconButton}
                        >
                          <UIIcon
                            fillOpacity={0.7}
                            src={MoreHorizontalLine}
                            className={cx(
                              styles.menuButton,
                              idx === editMenuOpen && styles.menuButtonShow,
                            )}
                          />
                        </IconButton>
                        <Menu.Content
                          {...getContentProps()}
                          positioningStrategy="fixed"
                          auto={false} // disable auto placement for demo purpose
                        >
                          <Menu.Segment>
                            <Menu.ActionItem
                              value={'edit'}
                              onClick={() => setEditingQuickLinkIndex(idx)}
                            >
                              Edit
                            </Menu.ActionItem>
                            <Menu.ActionItem
                              value={'delete'}
                              onClick={() => deleteQuickLink(idx)}
                            >
                              Delete
                            </Menu.ActionItem>
                          </Menu.Segment>
                        </Menu.Content>
                      </>
                    )}
                  </Menu.Wrapper>
                </div>
              </div>
            );
          })}
          {browserHistoryList.length < 6 && (
            <DigTooltip title={'Add more'} openDelay={150}>
              <Button
                variant="outline"
                size="large"
                className={cx(styles.button, styles.addMoreButton)}
                onClick={() => setEditingQuickLinkIndex(-1)}
              >
                <AddLine className={styles.faviconImg} />
              </Button>
            </DigTooltip>
          )}
          {placeholderArray.map((_, idx) => {
            return (
              <div key={idx} className={styles.placeHolder}>
                &nbsp;
              </div>
            );
          })}
        </div>
      )}
      {editingQuickLinkIndex >= -1 && (
        <EditQuickLinkModal
          onCancel={() => setEditingQuickLinkIndex(-2)}
          isOpen={true}
          quickLink={
            editingQuickLinkIndex >= 0
              ? browserHistoryList[editingQuickLinkIndex]
              : undefined
          }
          onSubmit={addOrUpdateQuickLink}
        />
      )}
    </div>
  );
};

const ResultContainer = styled.div<{ isDarkMode: boolean }>`
  position: relative;
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      ${(props) =>
        props.isDarkMode
          ? 'rgba(36, 33, 33, 0.2)'
          : 'rgba(255, 255, 255, 0.3)'},
      transparent
    );
    animation: shimmer 1.5s infinite;
  }
`;

const IconShimmer = styled.div<{ size?: string }>`
  flex-shrink: 0;
  width: ${({ size }) => size ?? '48px'};
  height: ${({ size }) => size ?? '48px'};
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: var(--dig-color__opacity__surface);
);
`;
