import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { DragHandleLine } from '@dropbox/dig-icons/assets';
import React from 'react';
import styles from './WithDragIconHandle.module.css';

const DEFAULT_POSITION_LEFT = -18;
const DEFAULT_DRAG_HANDLE_WIDTH = 18;

/**
 * HOC that wraps children with a drag handle icon that is positioned to the left on hover
 */
export const WithDragIconHandle = ({
  children,
  leftPosition = DEFAULT_POSITION_LEFT,
  width = DEFAULT_DRAG_HANDLE_WIDTH,
  topPosition,
  height,
}: {
  children: React.ReactNode;
  leftPosition?: number;
  width?: number;
  topPosition?: number;
  height?: number;
}) => {
  return (
    <div className={styles.overlayContainer}>
      {children}
      <div
        className={styles.dragIconContainer}
        style={{ left: leftPosition, width, top: topPosition ?? 0, height }}
      >
        {/* Note: Wrapping component with dig text fixes light/dark mode*/}
        <Text className={styles.textWrapper}>
          <UIIcon src={DragHandleLine} />
        </Text>
      </div>
    </div>
  );
};
