import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { DragHandleLine } from '@dropbox/dig-icons/assets';
import { useIsHoverFriendly } from '@mirage/shared/responsive/hover';
import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import styles from './AccessoryDragIndicator.module.css';

export const AccessoryDragIndicator = () => {
  const isHoverFriendly = useIsHoverFriendly();
  const isMobileSize = useIsMobileSize();
  const disableTooltip = !isHoverFriendly || isMobileSize;

  return (
    <Box className={styles.dragHandleIconContainer}>
      <DigTooltip
        title={i18n.t('drag_tooltip_title')}
        openDelay={200}
        disabled={disableTooltip}
      >
        <Text color="subtle" className={styles.iconWrapper}>
          <UIIcon src={DragHandleLine} />
        </Text>
      </DigTooltip>
    </Box>
  );
};
