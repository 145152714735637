import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';

type CleanupFn = () => void;
function isCleanupFn(value: CleanupFn | null): value is CleanupFn {
  return Boolean(value);
}

function tryToBlockDragging(element: Element): CleanupFn | null {
  if (
    !(
      element instanceof HTMLImageElement ||
      element instanceof HTMLAnchorElement
    )
  ) {
    return null;
  }

  // dragging already disabled, don't need to disable
  if (!element.draggable) {
    return null;
  }

  element.draggable = false;

  return function cleanup() {
    element.draggable = true;
  };
}

export function disableDraggingOnInternalImagesAndAnchors(
  element: HTMLElement,
): CleanupFn {
  const cleanups: CleanupFn[] = Array.from(element.querySelectorAll('a,img'))
    .map(tryToBlockDragging)
    .filter(isCleanupFn);

  return combine(...cleanups);
}

export function disableDraggingOnInternalImagesAndAnchorsWithObserver(
  element: HTMLElement,
) {
  let cleanup = disableDraggingOnInternalImagesAndAnchors(element);

  const observer = new MutationObserver(function onChange(
    event: MutationRecord[],
  ) {
    for (const record of event) {
      if (record.type !== 'childList') {
        return;
      }
      cleanup();
      cleanup = disableDraggingOnInternalImagesAndAnchors(element);
    }
  });

  observer.observe(element, {
    subtree: true,
    childList: true,
    attributes: false,
  });

  return () => {
    observer.disconnect();
  };
}
